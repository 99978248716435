import React from "react";
import createCRUDPage from "pages/Apps/Core/CreateCrudComponent";
import { Link } from "react-router-dom";
import { datacenterApiClient } from "backend";

export const Sessions = (props) => {
  const SessionsPage = createCRUDPage(
    "session-crud",
    datacenterApiClient,
    "Datacenter Sessions",
    props.customerCode,
    [
      {
        title: "ID",
        dataIndex: "id",
        render: (text, record) => (
          <Link to={`/datacenter-session/${text}`}>{text}</Link>
        ),
      },
    ]
  );

  return <SessionsPage />;
};
