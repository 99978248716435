import { datacenterApiClient } from "backend";
import createCRUDPage from "pages/Apps/Core/CreateCrudComponent";

const BleMaps = createCRUDPage(
  "blemap-crud",
  datacenterApiClient,
  "Datacenter BleMaps",
  null
);

export default BleMaps;
