import { bonjoappApiClient } from "backend";
import createCRUDPage from "pages/Apps/Core/CreateCrudComponent";

// Key.js
export const Keys = createCRUDPage(
  "keys-crud",
  bonjoappApiClient,
  "Bonjo Keys",
  "bonjoapp"
);

// KeyRedemptionRule.js
export const KeyRedemptionRules = createCRUDPage(
  "keyredemptionrules-crud",
  bonjoappApiClient,
  "Bonjo Key Redemption Rules",
  "bonjoapp"
);

// KeyRedemption.js
export const KeyRedemptions = createCRUDPage(
  "keyredemptions-crud",
  bonjoappApiClient,
  "Bonjo Key Redemptions",
  "bonjoapp"
);

// BVenueKeyDistributes.js
export const BVenueKeyDistributes = createCRUDPage(
  "venuekeydistributes-crud",
  bonjoappApiClient,
  "Bonjo Venue Key Distributes",
  "bonjoapp"
);

// BonjoUserKeypoints.js
export const BonjoUserKeypoints = createCRUDPage(
  "userkeypoints-crud",
  bonjoappApiClient,
  "Bonjo User Keypoints",
  "bonjoapp"
);

// BVenueKeyRedeems.js
export const BVenueKeyRedeems = createCRUDPage(
  "venuekeyredeems-crud",
  bonjoappApiClient,
  "Bonjo Venue Key Redeems",
  "bonjoapp"
);

// BonjoUserKeyRedeems.js
export const BonjoUserKeyRedeems = createCRUDPage(
  "userkeyredeems-crud",
  bonjoappApiClient,
  "Bonjo User Key Redeems",
  "bonjoapp"
);
