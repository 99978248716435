import createCRUDPage from "pages/Apps/Core/CreateCrudComponent";
import { datacenterApiClient } from "backend";

const Venues = createCRUDPage(
  "venue-crud",
  datacenterApiClient,
  "Datacenter Venues",
  null,
  {},
  {
    photo_url: {},
    working_hours: {},
    about: {},
    popular_times: {},
  },
  ["name", "province", "city"]
);

export default Venues;
