import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb

import avatar from "../../assets/images/users/avatar-1.jpg";

// actions
import { editProfile, resetProfileFlag } from "../../slices/thunk";
import { createSelector } from "reselect";
import { Alert, Card, Col, Form, Input, Row, Button, Typography } from "antd";
import Breadcrumb from "../../Common/Breadcrumb";
import usecustomStyles from "../../Common/customStyles";
import withRouter from "../../Common/withRouter";
import { useCookies } from "react-cookie";

const customStyles = usecustomStyles();

const { Text } = Typography;

const UserProfile = () => {
  // page title
  document.title = "User Profile" + process.env.REACT_APP_PAGE_TITLE;

  const dispatch = useDispatch();

  const [email, setemail] = useState("default@default.com");
  const [password, setPassword] = useState("");

  const [cookies, setCookie] = useCookies([
    "datacenter_access_token",
    "datacenter_refresh_token",
    "authUser",
  ]);

  let user = cookies.authUser;

  useEffect(() => {
    setemail(user.email || user.data.email);
    setTimeout(() => {
      dispatch(resetProfileFlag());
    }, 3000);
  }, [dispatch, user]);

  function handleOnSubmit() {
    console.log(password);
  }

  function handleOnChange(values) {
    setPassword(values);
  }

  return (
    <React.Fragment>
      <div>
        <div style={{ marginTop: "24px" }}>
          <Text
            style={{
              PaddingBottom: "8px",
              display: "block",
              marginBottom: customStyles.marginSM,
            }}
          >
            Change Password for {email}
          </Text>

          <Card>
            <Form>
              <div className="form-group">
                <label style={{ display: "block", marginBottom: "4px" }}>
                  New Password
                </label>
                <Input
                  name="password"
                  placeholder="Enter New Password"
                  style={{ boxShadow: "none" }}
                  type="text"
                  onChange={(e) => {
                    handleOnChange(e.target.value);
                  }}
                  // onBlur={validation.handleBlur}
                />
              </div>
              <div style={{ marginTop: "30px" }} className="text-center">
                <Button
                  htmlType="submit"
                  style={{
                    backgroundColor: customStyles.colorDanger,
                    color: "white",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    handleOnSubmit();
                    return false;
                  }}
                >
                  Update Password
                </Button>
              </div>
            </Form>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
