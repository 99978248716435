import React from "react";
import createCRUDPage from "pages/Apps/Core/CreateCrudComponent";
import { datacenterApiClient } from "backend";

const WifiMapsPage = () => {
  // Create the component using createCRUDPage with the provided customerCode
  const WifiMaps = createCRUDPage(
    "wifimap-crud",
    datacenterApiClient,
    "Datacenter WifiMaps",
    null
  );

  // Render the dynamically created component
  return <WifiMaps />;
};

export default WifiMapsPage;
