import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Pagination,
  Select,
  Row,
  Col,
} from "antd";
import { get } from "lodash";

const CRUDComponent = ({
  modelName,
  createCRUDFunctions,
  client,
  readOnly = false,
  updateItself = false,
  dataChangedNotifier = () => {},
  specialRenderedComponents = {},
  doNotDisplayComponents = {},
  customerCode = null,
  filterableColumns = [],
}) => {
  const {
    getColumns,
    getAllItems,
    addItem,
    updateItem,
    deleteItem,
    getUniqueColumnValues,
  } = createCRUDFunctions(modelName, client, customerCode);

  const [items, setItems] = useState([]);
  const [columns, setColumns] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [selectedItem, setSelectedItem] = useState(null);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);

  // Filter state
  const [filters, setFilters] = useState({});
  const [filterOptions, setFilterOptions] = useState({});

  useEffect(() => {
    fetchColumns();
    fetchData(currentPage, pageSize);
    fetchFilterOptions();
  }, [modelName, updateItself, currentPage, pageSize]);

  useEffect(() => {
    console.log(filters);
  }, [filters]);

  const fetchFilterOptions = async () => {
    try {
      const options = {};

      for (const columnKey of filterableColumns) {
        const uniqueValues = await getUniqueColumnValues(columnKey);
        options[columnKey] = uniqueValues;
      }
      setFilterOptions(options);
    } catch (error) {
      console.error("Error fetching filter options:", error);
    }
  };

  const fetchColumns = async () => {
    try {
      const data = await getColumns();
      const mappedColumns = data
        .filter((column) => !doNotDisplayComponents[column])
        .map((column) => {
          if (!specialRenderedComponents[column]) {
            return {
              title: column,
              dataIndex: column,
              key: column,
            };
          } else {
            return {
              title: column,
              dataIndex: column,
              key: column,
              render: (text, record) => {
                return specialRenderedComponents[column](text, record);
              },
            };
          }
        });

      setColumns(
        !readOnly
          ? [
              ...mappedColumns,
              {
                title: "Action",
                key: "action",
                render: (text, record) => (
                  <span>
                    <Button
                      onClick={() => showEditModal(record)}
                      style={{ margin: "3px" }}
                    >
                      Edit
                    </Button>
                    <Button
                      onClick={() => showDeleteConfirm(record.id)}
                      danger
                      style={{ margin: "3px" }}
                    >
                      Delete
                    </Button>
                  </span>
                ),
              },
            ]
          : [...mappedColumns]
      );
    } catch (error) {
      console.error("Error fetching columns:", error);
    }
  };

  const fetchData = async (page, size) => {
    setIsDataLoading(true);
    try {
      const filterCriteria = { ...filters }; // Pass the selected filters
      const data = await getAllItems({
        limit: size,
        offset: (page - 1) * size,
        filters: filters, // Add filters here
      });

      const total = data.total || 0;
      setTotalItems(total);
      setItems(data.items?.map((value) => ({ key: value.id, ...value })));
    } catch (error) {
      console.error(`Error fetching ${modelName} data:`, error);
    }
    setIsDataLoading(false);
  };

  const handleAdd = async (values) => {
    try {
      const newItem = await addItem(values);
      setItems([...items, { key: newItem.id, ...newItem }]);
      form.resetFields();
      setIsModalVisible(false);
      dataChangedNotifier();
    } catch (error) {
      console.error("Error adding item:", error);
    }
  };

  const handleUpdate = async (values) => {
    if (!selectedItem) return;
    try {
      const updatedItem = await updateItem(selectedItem.id, values);
      const updatedItems = items.map((item) =>
        item.id === updatedItem.id
          ? { key: updatedItem.id, ...updatedItem }
          : item
      );
      setItems(updatedItems);
      form.resetFields();
      setIsModalVisible(false);
      setSelectedItem(null);
      dataChangedNotifier();
    } catch (error) {
      console.error("Error updating item:", error);
    }
  };

  const handleFilterChange = (value, columnKey) => {
    setFilters((prevFilters) => {
      // Always set the selected values for the column to the new list of selected options (value).
      // `value` will be an array of the currently selected options.
      return {
        ...prevFilters,
        [columnKey]: value, // The new array of selected values
      };
    });
  };

  const handleApplyFilters = () => {
    fetchData(currentPage, pageSize); // Apply filters and fetch data
  };

  const showDeleteConfirm = (itemId) => {
    Modal.confirm({
      title: "Are you sure you want to delete this item?",
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        await handleDelete(itemId);
      },
    });
  };

  const handleDelete = async (itemId) => {
    try {
      await deleteItem(itemId);
      setItems(items.filter((item) => item.id !== itemId));
      fetchData(currentPage, pageSize);
      dataChangedNotifier();
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  const showEditModal = (record) => {
    setSelectedItem(record);
    form.setFieldsValue(record);
    setIsModalVisible(true);
  };

  const showAddModal = () => {
    form.resetFields();
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedItem(null);
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
    fetchData(page, pageSize);
  };

  return (
    <div>
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={totalItems}
        onChange={handlePageChange}
        style={{ float: "left", margin: 16 }}
      />

      {filterableColumns.length > 0 && (
        <Button
          onClick={() => {
            setIsFilterModalVisible(true);
          }}
          type="link"
          style={{ float: "right", margin: 8 }}
        >
          Filter
        </Button>
      )}

      {!readOnly && (
        <>
          <Button
            onClick={showAddModal}
            type="primary"
            style={{ float: "right", margin: 8 }}
          >
            Add
          </Button>
        </>
      )}

      <Table
        dataSource={items}
        columns={columns}
        rowKey="id"
        pagination={false}
        loading={isDataLoading}
      />

      <Modal
        title={selectedItem ? `Edit ${modelName}` : `Add ${modelName}`}
        visible={isModalVisible}
        onOk={form.submit}
        onCancel={handleCancel}
        destroyOnClose
      >
        <Form form={form} onFinish={selectedItem ? handleUpdate : handleAdd}>
          {columns
            .filter((column) => column.key !== "action")
            .map((column) => (
              <Form.Item
                key={column.key}
                name={column.dataIndex}
                label={column.title}
                rules={[
                  { required: true, message: `Please input ${column.title}!` },
                ]}
              >
                <Input />
              </Form.Item>
            ))}
        </Form>
      </Modal>

      <Modal
        title="Filters"
        visible={isFilterModalVisible}
        onOk={() => {
          handleApplyFilters();
          setIsFilterModalVisible(false);
        }}
        onCancel={() => {
          setIsFilterModalVisible(false);
        }}
        destroyOnClose
      >
        {Object.keys(filterOptions).map((columnKey) => (
          <Row gutter={24} style={{ marginBottom: 16 }}>
            <Col span={8}>{columnKey} : </Col>
            <Col span={16} key={columnKey}>
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                placeholder={`Filter by ${columnKey}`}
                value={filters[columnKey] || []} // Ensure it is controlled and defaults to an empty array if no values are selected
                onChange={(value) => handleFilterChange(value, columnKey)} // Update the filters state with all selected values
              >
                {filterOptions[columnKey]?.map((option) => (
                  <Select.Option key={option} value={option}>
                    {option}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
        ))}
      </Modal>
    </div>
  );
};

export default CRUDComponent;
