import React, { useState } from "react";
import withRouter from "../Common/withRouter";
import BrandLogo from "../assets/images/logo-dark.png";
import BrandlightLogo from "../assets/images/logo-light.png";
import BrandSmLogo from "../assets/images/logo-sm.png";
import { Menu } from "antd";
import {
  AlertCircle,
  Archive,
  ArrowRightLeft,
  CandlestickChart,
  ChevronLeft,
  ChevronRight,
  Combine,
  Component,
  Crown,
  Gauge,
  GalleryHorizontal,
  Gavel,
  Gem,
  KeyRound,
  LayoutList,
  Mail,
  // Map,
  MessagesSquare,
  Radar,
  Rat,
  Store,
  UserCircle2,
  UserSquare,
  Wifi,
  Shell,
  BookUser,
  Bluetooth,
  Info,
  QrCode,
  Map,
  Calendar,
} from "lucide-react";
import {
  Tag,
  ShoppingCart,
  Star,
  CreditCard,
  CheckSquare,
  Users,
  Home,
  UserPlus,
  Medal,
  User,
  Bell,
  CheckCircle,
  MessageCircle,
  Eye,
  MessageSquare,
  Lightbulb,
  Briefcase,
  ShoppingBag,
  Key,
  Globe,
  MapPin,
  Unlock,
  Shield,
  List,
  Gift,
  HelpCircle,
} from "lucide-react";

import { themecolor } from "../config.js";
import {
  StyleSimpleBar,
  StyledCollapsedButton,
  StyleBrandLogo,
  StyleSider,
} from "../Common/SidebarStyle";
import { Link, useLocation } from "react-router-dom";
import { capitalizeFirstLetter } from "../helpers/common_helper_functions";
import { useCookies } from "react-cookie";

const SidebarLayout = ({ theme }) => {
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const createMenuItem = (appName, featureName, IconComponent) => {
    return getItem(
      <Link to={`/${appName}-${featureName}`}>
        {capitalizeFirstLetter(featureName)}
      </Link>,
      `/${appName}-${featureName}`,
      <IconComponent size={16} />
    );
  };

  const [cookies, setCookie] = useCookies([
    "datacenter_access_token",
    "datacenter_refresh_token",
    "authUser",
  ]);

  let domain = cookies.authUser.email.split("@")[1];
  let THEDOOR = "thedoor.social";
  let BONJO = "bonjoapp.com";

  const includeDashboardLinks = [THEDOOR].includes(domain);
  const includeDatacenterLinks = [THEDOOR].includes(domain);
  const includeBonjoappLinks = [THEDOOR, BONJO].includes(domain);
  const includeSpeakeasyLinks = [THEDOOR].includes(domain);

  const dashboard_links = [
    getItem(
      <Link to="/dashboard">Dashboard</Link>,
      "dashboard",
      <Gauge size={16} />
    ),
  ];

  const datacenter_links = [
    getItem(
      "DATACENTER",
      "DATACENTER",
      null,
      [
        createMenuItem("datacenter", "accounts", BookUser),
        createMenuItem("datacenter", "venues", Store),
        createMenuItem("datacenter", "sessions", Shell),
        createMenuItem("datacenter", "wifimaps", Wifi),
        createMenuItem("datacenter", "blemaps", Bluetooth),
        createMenuItem("datacenter", "geofences", Map),
      ],
      "group"
    ),
  ];

  const bonjoapp_links = [
    getItem(
      "BONJOAPP",
      "BONJOAPP",
      null,
      [
        // createMenuItem("bonjoapp", "accounts", BookUser),
        createMenuItem("bonjoapp", "appcomments", MessageSquare),
        createMenuItem("bonjoapp", "cities", MapPin),
        createMenuItem("bonjoapp", "companyroles", Briefcase),
        createMenuItem("bonjoapp", "communicationmethods", MessageSquare),
        createMenuItem("bonjoapp", "countries", Globe),
        createMenuItem("bonjoapp", "counties", Map),
        createMenuItem("bonjoapp", "frequentlyaskedquestions", HelpCircle),
        createMenuItem("bonjoapp", "invitetokenteammembers", UserPlus),
        createMenuItem("bonjoapp", "keycategories", Key),
        createMenuItem("bonjoapp", "keycategoriesproducts", Key),
        createMenuItem("bonjoapp", "keyredemptionrules", CheckSquare),
        createMenuItem("bonjoapp", "keyredemptions", Unlock),
        createMenuItem("bonjoapp", "keys", Key),
        createMenuItem("bonjoapp", "notifications", Bell),
        createMenuItem("bonjoapp", "notificationtypes", AlertCircle),
        createMenuItem("bonjoapp", "profilefavorites", Star),
        createMenuItem("bonjoapp", "profilefavoritelists", List),
        createMenuItem("bonjoapp", "profilefavoritelistsfavorites", Star),
        createMenuItem("bonjoapp", "products", ShoppingBag),
        createMenuItem("bonjoapp", "profiles", User),
        createMenuItem("bonjoapp", "qrcodes", QrCode),
        createMenuItem("bonjoapp", "sessions", Calendar),
        // createMenuItem("bonjoapp", "store", Store),
        createMenuItem("bonjoapp", "teammembers", Users),
        // createMenuItem("bonjoapp", "user", User),
        createMenuItem("bonjoapp", "usercheckin", CheckCircle),
        createMenuItem("bonjoapp", "usercommunicationmethods", MessageCircle),
        createMenuItem("bonjoapp", "uservenuecheckin", CheckCircle),
        createMenuItem("bonjoapp", "usercontactus", MessageSquare),
        createMenuItem("bonjoapp", "uservenueview", Eye),
        createMenuItem("bonjoapp", "uservenues", Home),
        createMenuItem("bonjoapp", "uservenuesuggestion", Lightbulb),
        createMenuItem("bonjoapp", "userkeypoints", Star),
        createMenuItem("bonjoapp", "userkeyredeems", CheckCircle),
        createMenuItem("bonjoapp", "userkeyredeems", CheckCircle),
        createMenuItem("bonjoapp", "usernotificationtypes", Bell),
        createMenuItem("bonjoapp", "venuememberships", Shield),
        createMenuItem("bonjoapp", "venuemembershipsstatus", Info),
        createMenuItem("bonjoapp", "venuebadges", Medal),
        createMenuItem("bonjoapp", "venuecapacities", Users),
        createMenuItem("bonjoapp", "venuefeatures", Star),
        createMenuItem("bonjoapp", "venuekeydistributes", Gift),
        createMenuItem("bonjoapp", "venuekeyredeems", CheckCircle),
        createMenuItem("bonjoapp", "venuephotogalleries", GalleryHorizontal),
        createMenuItem("bonjoapp", "venuepaymenttypes", CreditCard),
        createMenuItem("bonjoapp", "venuetypes", Tag),
        createMenuItem("bonjoapp", "venueproducts", ShoppingCart),
        createMenuItem("bonjoapp", "venueteammembers", Users),
        createMenuItem("bonjoapp", "venuekeyredeems", CheckCircle),
      ],
      "group"
    ),
  ];

  const speakeasy_links = [
    getItem(
      "SPEAKEASY",
      "SPEAKEASY",
      null,
      [
        createMenuItem("speakeasy", "user", UserSquare),
        createMenuItem("speakeasy", "profile", UserSquare),
        createMenuItem("speakeasy", "chat", MessagesSquare),
      ],
      "group"
    ),
  ];

  const items = [
    ...(includeDashboardLinks ? dashboard_links : []),
    ...(includeDatacenterLinks ? datacenter_links : []),
    ...(includeBonjoappLinks ? bonjoapp_links : []),
    ...(includeSpeakeasyLinks ? speakeasy_links : []),
  ];

  console.log(items);

  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
    const antHeaderMain = document.getElementById("antHeaderMain");
    if (antHeaderMain) {
      antHeaderMain.style.left = !collapsed ? "100px" : "260px";
    }
    const antLayoutContent = document.getElementById("antLayoutContent");
    if (antLayoutContent) {
      antLayoutContent.style.marginLeft = !collapsed ? "100px" : "260px";
    }
    const antFooterLayout = document.getElementById("antFooterLayout");
    if (antFooterLayout) {
      antFooterLayout.style.marginLeft = !collapsed ? "100px" : "260px";
    }
  };

  const location = useLocation();
  const [activatedItem, setActivatedItem] = useState(() => {
    const currentPath = location.pathname.replace("/", "");
    return currentPath || "dashboard";
  });

  const toggleActivation = (key) => {
    setActivatedItem((prevActivatedItem) =>
      prevActivatedItem === key ? null : key
    );
  };

  return (
    <React.Fragment>
      <StyleSider
        id="sidebar-layout"
        width={themecolor.components.Menu.verticalSidebarWidth}
        collapsed={collapsed}
        collapsedWidth="100"
        breakpoint="lg"
      >
        <StyleBrandLogo className="demo-logo ant-mx-auto">
          <img
            alt="Brand logo"
            src={theme === "dark" ? BrandlightLogo : BrandLogo}
            height={30}
            style={{ lineHeight: "24px" }}
            className="brand-dark-logo ant-mx-auto"
          />
          {/* <img
            alt="Brand sm logo"
            src={BrandSmLogo}
            height={24}
            style={{ lineHeight: "24px" }}
            className="brand-sm-logo ant-mx-auto"
          /> */}
          <StyledCollapsedButton
            themecolor={themecolor}
            type="link"
            onClick={toggleCollapsed}
          >
            {collapsed ? <ChevronRight /> : <ChevronLeft />}
          </StyledCollapsedButton>
        </StyleBrandLogo>
        <div>
          <StyleSimpleBar>
            <Menu
              selectedKeys={[activatedItem]}
              mode="inline"
              theme="light"
              items={items}
              collapsedWidth="100"
              onClick={({ key }) => toggleActivation(key)}
            ></Menu>
          </StyleSimpleBar>
        </div>
      </StyleSider>
    </React.Fragment>
  );
};

export default withRouter(SidebarLayout);
