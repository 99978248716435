import usecustomStyles from "Common/customStyles";
import ContentHeader from "../ContentHeader";
import { Card, Col, Form, Row, Table } from "antd";
import MapComponent from "../Core/MapComponent";
import React, { useEffect, useState } from "react";
import GeofenceMapComponent from "../Core/GeofenceMapComponent";
import CRUDComponent from "../../CRUD/CRUDComponent"; // Ensure the correct path
import { getSessionData } from "./DataCenterAPIClient";

import { useParams } from "react-router-dom";
import createCRUDFunctions from "pages/CRUD/CRUDFunctions";
import { datacenterApiClient } from "backend";

const Session = () => {
  const { id } = useParams();
  const session_id = id;
  const customStyles = usecustomStyles();
  const modelName = "session-detail-crud";
  const [sessionData, setSessionData] = useState([]);
  const [columnName, setColumnNames] = useState([]);
  const {
    getColumns,
    _getAllItems,
    addItem,
    updateItem,
    deleteItem,
    getUniqueColumnValues,
  } = createCRUDFunctions(datacenterApiClient, modelName);

  const getAllItems = async () => {
    const data = await getSessionData(session_id);
    return data;
  };

  const newcrud = (modelName) => {
    return {
      getColumns,
      getAllItems,
      addItem,
      updateItem,
      deleteItem,
    };
  };

  const loadData = async () => {
    const columns = await getColumns();
    const data = await getAllItems();
    // console.log("setSessionData", data);
    // console.log("setColumnNames", columns);
    setSessionData(data);
    setColumnNames(columns);
  };

  useEffect(() => {
    // console.log("in_session");
    loadData();
  }, []);

  return (
    <>
      <ContentHeader headerTitle={"Session : " + session_id} />

      <Row gutter={[24]} style={{ marginTop: "-20px" }}>
        <Col xs={24} className="gutter-row" style={{ overflowX: "auto" }}>
          <Card
            style={{ marginBottom: customStyles.margin, overflowX: "auto" }}
          >
            <CRUDComponent
              modelName={modelName}
              client={datacenterApiClient}
              createCRUDFunctions={newcrud}
              readOnly={true}
              specialRenderedComponents={{
                location_sample: (text, record) => {
                  return (
                    <span>
                      {record.location_sample.latitude} {" , "}
                      {record.location_sample.longitude}
                    </span>
                  );
                },
                bluetooth_sample: (text, record) => {
                  return <span>{record.bluetooth_sample.bluetooth_count}</span>;
                },
                wifi_sample: (text, record) => {
                  return <span>{record.wifi_sample.wifi_signals.length}</span>;
                },
              }}
            />
          </Card>
          <Card
            style={{
              marginBottom: customStyles.margin,
              marginTop: "20px",
              overflowX: "auto",
            }}
          >
            <MapComponent data={sessionData} />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Session;
